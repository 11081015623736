import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"
import React, { FormEvent, useRef, useState } from "react"
import {
  Authenticated,
  useAuthenticated,
} from "../../components/auth/Authenticated"
import { FormVStack } from "../../components/Form"
import { FormLayout } from "../../components/layout/FormLayout"
import { PasswordInput } from "../../components/auth/PasswordInput"

export function UpdatePasswordForm() {
  const { user } = useAuthenticated()
  const passwordRef = useRef<HTMLInputElement>(null)

  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  async function handleSubmit(e: FormEvent) {
    e.preventDefault()
    setIsLoading(true)
    setError("")
    setIsSuccess(false)

    if (!passwordRef.current) {
      throw new Error("Expected `passwordRef.current` to be defined")
    }

    try {
      setError("")
      setIsLoading(true)
      await user.updatePassword(passwordRef.current.value)
      setIsSuccess(true)
    } catch (err) {
      setError(err.message)
    }

    passwordRef.current.value = ""

    setIsLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormVStack>
        {error && (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        )}
        {isSuccess && (
          <Alert status="success">
            <AlertIcon />
            You password has been updated.
          </Alert>
        )}
        <FormControl>
          <FormLabel>New password</FormLabel>
          <PasswordInput
            isRequired
            inputRef={passwordRef}
            autoFocus
            isNewPassword
            size="lg"
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          size="lg"
          isLoading={isLoading}
        >
          Save
        </Button>
      </FormVStack>
    </form>
  )
}

export default function UpdatePassword() {
  return (
    <FormLayout title="🗝 Update Password" showBack>
      <Authenticated>
        <UpdatePasswordForm />
      </Authenticated>
    </FormLayout>
  )
}
